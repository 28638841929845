/* @import url("https://fonts.googleapis.com/css2?family=Barlow:wght,wght@1,200&display=swap"); */
/* @import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;600&family=Questrial&display=swap'); */

body {
  margin: 0;
  font-family: "UniversNextforHSBC-Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* html,body,#root,.App{height: 100vh; overflow-y: scroll;} */

html,
body,
#root,
.App {
  height: 90vh !important;

}

.react-tel-input {
  margin-top: 16px;
}

.react-tel-input .special-label {
  color: rgba(0, 0, 0, 0.54);
}

*:focus {
  outline: none !important;
}

/* 
.App {
  height: 100vh !important;
  overflow-y: auto; 
} */

code {
  font-family: "UniversNextforHSBC-Regular", sans-serif;
}

.confirmButtonSweet {
  background-color: #DB0011 !important;
  border-color: #FFFFFF;
  min-width: 270px !important;
  /* letter-spacing: 2px; */
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 0px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size:
    /* 0.75rem */
    16px !important;
  font-weight: 700;
}

.confirmButtonSweetWarning {
  background-color: #FFFFFF !important;
  border-color: #DB0011 !important;
  min-width: 270px !important;
  /* letter-spacing: 2px; */
  width: 270px !important;
  height: 45px !important;
  border: 1px solid #DB0011 !important;
  border-radius: 0px !important;
  padding: 0;
  color: #DB0011 !important;
  font-size:
    /* 0.75rem */
    16px !important;
  font-weight: 700;
}

.confirmButtonSweetCustom {
  background-color: #fff !important;
  color: #000 !important;
  border-radius: 0px !important;
  min-width: 270px !important;
}

.confirmButtonSweetConfirm {
  background-color: #DB0011 !important;
  /* border-radius: 25px !important;  */
  border-color: #FFFFFF;
  min-width: 185px !important;
  /* letter-spacing: 2px; */
  width: 185px !important;
  height: 45px !important;
  border: 1px solid #FFFFFF !important;
  border-radius: 0px !important;
  padding: 0;
  color: #FFFFFF !important;
  font-size:
    /* 0.75rem */
    16px !important;
  font-weight: 700;
}

.confirmButtonSweetCancel {
  background-color: #FFFFFF !important;
  border-color: #333333;
  min-width: 185px !important;
  letter-spacing: 1px;
  width: 185px !important;
  height: 45px !important;
  border: 1px solid #333333 !important;
  border-radius: 0px !important;
  padding: 0;
  color: #333333 !important;
  font-size:
    /* 0.75rem */
    16px !important;
  font-weight: 700;
  font-family: 'UniversNextforHSBC-Regular', sans-serif !important;
}

.confirmButtonSweetCancel:hover {
  background-color: '#F3F3F3';
  border-color: '#333333';
}

.confirmButtonSweet:focus,
.confirmButtonSweetCustom:focus,
.confirmButtonSweetConfirm:focus,
.confirmButtonSweetCancel:focus {
  box-shadow: none !important;
}

.confirmButtonSweetWarning:focus,
.confirmButtonSweetWarning:focus {
  box-shadow: none !important;
}

.swal2-container {
  z-index: 90001 !important;
}

.react-tel-input .form-control {
  width: 100%;
  height: 45px;
  border-radius: 0px;
  font-size: 16px;
}

.swal2-popup {
  max-width: 600px;
  padding: 0 0 1.25em !important;
}

.swal2-icon-alertNoti {
  border: none;
  border-color: #FFFFFF !important;
  width: 90% !important;
  /* height: 30px !important; */
  /* width: 7em !important;
  height: 6em !important; */
  /* margin: .1em auto .1em !important; */
  margin: 2.5em auto 0.6em !important;
}

.swal2-icon .swal2-icon-content {
  max-width: 20%;
  justify-content: center;
}

.swal2-icon-content-img {
  /* padding-top: 0.5em; */
  /* width: 130px;
  height: 130px; */
}

.swal2-icon-content {
  /* width: 150px;
  height: 150px; */
}

.dstoast {
  padding: 8px !important;
}

.dstoast .swal2-title {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.videoUbi {
  width: 80%;
  object-fit: cover;
  object-position: center center;
}

@media only screen and (max-width: 812px) {
  .swal2-popup {
    max-width: 280px;
  }

  .swal2-icon .swal2-icon-content {
    max-width: 100%;
    justify-content: center;
  }

  .swal2-icon-content-img {
    width: 70px !important;
    height: 60px !important;
  }

  .swal2-html-container {
    margin: 1em 0.6em .3em;
    font-size: 15px;
  }

  .confirmButtonSweet {
    background-color: #DB0011 !important;
    /* border-radius: 25px !important;  */
    min-width: 240px !important;
    width: 240px !important;
    height: 38px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 0px !important;
    padding: 0;
    color: #FFFFFF !important;
    font-size:
      /* 0.75rem */
      16px !important;
  }

  .confirmButtonSweetConfirm {
    background-color: #DB0011 !important;
    /* border-radius: 25px !important;  */
    border-color: #FFFFFF;
    min-width: 185px !important;
    /* letter-spacing: 2px; */
    width: 185px !important;
    height: 45px !important;
    border: 1px solid #FFFFFF !important;
    border-radius: 0px !important;
    padding: 0;
    color: #FFFFFF !important;
    font-size:
      /* 0.75rem */
      16px !important;
    font-weight: 700;
  }

  .confirmButtonSweetCancel {
    background-color: #FFFFFF !important;
    border-color: #333333;
    min-width: 185px !important;
    letter-spacing: 1px;
    width: 185px !important;
    height: 45px !important;
    border: 1px solid #333333 !important;
    border-radius: 0px !important;
    padding: 0;
    color: #333333 !important;
    font-size:
      /* 0.75rem */
      16px !important;
    font-weight: 700;
    font-family: 'UniversNextforHSBC-Regular', sans-serif !important;
  }

  .swal2-icon-alertNoti {
    border: none;
    border-color: #FFFFFF !important;
    width: 100% !important;
    /* height: 30px !important; */
    /* width: 7em !important;
    height: 6em !important; */
    margin: .1em auto .1em !important;
  }
}

@font-face {
  font-family: 'UniversNextforHSBC-Regular';
  src: local('UniversNextforHSBC-Regular'), url("./assets/Tipografias/UniversNextforHSBC-Regular.otf");
  /* font-family: 'UniversNextforHSBC-Regular', sans-serif !important; */
  /*Can be any text*/
  /* src: local("AirbnbCerealLight"),
    url("./font/Lexend-Light.ttf") format("truetype"); */
}